/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import McOutputMcs from '../model/McOutputMcs';
import McOutputRouteMcSubmit from '../model/McOutputRouteMcSubmit';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';

/**
* OrgGame service.
* @module api/OrgGameApi
* @version 1.0
*/
export default class OrgGameApi {

    /**
    * Constructs a new OrgGameApi. 
    * @alias module:api/OrgGameApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Submit mcs answers
     * @param {Number} orgId org id
     * @param {Object.<String, {String: Number}>} requestBody answers record
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/McOutputRouteMcSubmit} and HTTP response
     */
    orgGameMcSubmitAnswersWithHttpInfo(orgId, requestBody) {
      let postBody = requestBody;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgGameMcSubmitAnswers");
      }

      // verify the required parameter 'requestBody' is set
      if (requestBody === undefined || requestBody === null) {
        throw new Error("Missing the required parameter 'requestBody' when calling orgGameMcSubmitAnswers");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = McOutputRouteMcSubmit;

      return this.apiClient.callApi(
        '/org/{orgId}/game/mc/submit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Submit mcs answers
     * @param {Number} orgId org id
     * @param {Object.<String, {String: Number}>} requestBody answers record
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/McOutputRouteMcSubmit}
     */
    orgGameMcSubmitAnswers(orgId, requestBody) {
      return this.orgGameMcSubmitAnswersWithHttpInfo(orgId, requestBody)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get random mcs
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/McOutputMcs} and HTTP response
     */
    orgGameMcsWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgGameMcs");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = McOutputMcs;

      return this.apiClient.callApi(
        '/org/{orgId}/game/mc', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get random mcs
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/McOutputMcs}
     */
    orgGameMcs(orgId) {
      return this.orgGameMcsWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
