export default {
    'en': {
        'enterGame': 'Start Game',
        'login': 'Login',
        'modalCompetitionSubmissionErrMessage': 'Submission Failed.',
        'modalCompetitionSubmissionSuccessMessage': 'Submission Successful.',
        'ok': 'OK',
        'submission': 'Submit Review',
        'howToUpload': 'How to submit?',
        'gameInstructionTitle': 'Ultimate Challenge: Creative Waste Reduction',
        'gameInstructionDescription': `Suggest a creative method for reducing waste and upload it as a short video\n
            Game Detail:\n
            - 1-minute video\n
            - Explain your idea on how to reduce waste\n
            - Explain how you come up with the idea\n
            - Video format: mp4 (<100MB)\n
            - The most creative applicant will receive an award\n
            - 3 awards for upper primary and 3 awards for lower primary`,
        'tabRanking': 'Class Ranking',
        'tabVideos': 'Student Reviews',
        'loadMore': 'Load More',
    },
    'tc': {
        'enterGame': '開始遊戲',
        'login': '登入',
        'modalCompetitionSubmissionErrMessage': '提交不成功。',
        'modalCompetitionSubmissionSuccessMessage': '提交成功。',
        'ok': '明白',
        'submission': '提交心得',
        'howToUpload': '如何上傳你的作品？',
        'gameInstructionTitle': '終極任務: 一人一[橋]比賽',
        'gameInstructionDescription': `發揮創意, 創作出新穎的減廢方法, 上傳於平台\n
            遊戲細則\n
            - 一分鐘短片\n
            - 講解如何減廢\n
            - 講解為可有此想法\n
            - 短片格式：mp4 (<100 MB)\n
            - 最具創意的可獲得獎項\n
            - 高年班及低年班組別各頭3名`,
        'tabRanking': '班級排名',
        'tabVideos': '學生心得',
        'loadMore': '加載更多',
    }
}