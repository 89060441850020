import React, { setState } from 'react';
import { withRouter } from 'react-router-dom';

import './style.scss';

const ButtonWithIcon = (props) => {
    const { history, iconUrl, btnText, targetUrl, onClick } = props;

    return (
        <div className="ButtonWithIcon" onClick={(e) => targetUrl ? history.push(targetUrl) : onClick()}>
            <div className="ButtonWithIcon-icon">
                <img src={iconUrl} alt=""/>
            </div>
            <div className="ButtonWithIcon-btn">
                {btnText}
            </div>
        </div>
    )
}

export default withRouter(ButtonWithIcon);