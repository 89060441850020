import React, {Component} from 'react';
import {Button, Form, Icon, Input, Modal, Select, Spin, Upload} from 'antd';
import _ from 'lodash';

import './UploadPhotoModal.scss';

import OrgCompetitionApi from "../../apiClient/api/OrgCompetitionApi";
import SubmissionCreateSubmissionParam from "../../apiClient/model/SubmissionCreateSubmissionParam";
import {OrgUserApi} from "../../apiClient";
import {matchPath} from "react-router-dom";

const FormItem = Form.Item;
const Option = Select.Option;

export class UploadPhotoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewImage: null,
            ableUpload: false
        }
    }

    handleSubmit(e) {
        const {afterSubmit, form} = this.props;
        e.preventDefault();

        form.validateFields((err, values) => {
            if (err) {
                console.log(err);
            } else {
                afterSubmit(values);
            }
        });
    }

    onUploadChange(f) {
        console.log(f.file);
        let reader = new FileReader();
        reader.readAsDataURL(f.file.originFileObj);

        reader.onloadend = function () {
            this.setState({
                previewImage: f.file.name,
                ableUpload: true,
            });
        }.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {visible, form} = this.props;
        if (prevProps.visible !== visible) {
            form.resetFields();
        }
    }

    render() {
        const {title, description, howToUpload, form, ableSubmissionUsers, isSubmitting, ...props} = this.props;
        const {getFieldDecorator} = form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
        };

        const classOptions = _.map(ableSubmissionUsers, (classObj) => <Option key={classObj.id}
                                                                              value={classObj.id}>{classObj.name}</Option>);

        return (
            <Modal
                className="UploadPhotoModal"
                width="70%"
                title={null}
                footer={null}
                {...props}
            >
                <Spin spinning={isSubmitting} tip="Uploading...">
                    <h1 style={{lineHeight:1.2}}>{title}</h1>
                    <p style={{fontSize: 16, lineHeight: 1.2,textAlign:'left', margin: 10, whiteSpace: 'pre-line', textAlign: 'center'}}>{description}</p>
                    <Form onSubmit={(e) => this.handleSubmit(e)}>
                        <p style={{textAlign: 'right'}}><a target="_blank" href="/upload-work-tut/index.html">{howToUpload}</a> </p>
                        <br/>
                        <FormItem
                            {...formItemLayout}
                            label="Class"
                        >
                            {getFieldDecorator('class')(
                                <Select
                                    size="large"
                                >
                                    {classOptions}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Student Name"
                        >
                            {getFieldDecorator('name')(
                                <Input
                                    size="large"
                                />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Video"
                        >
                            <div className="dropbox">
                                {getFieldDecorator('photo', {
                                    valuePropName: 'photo',
                                })(
                                    <Upload.Dragger
                                        name="photo"
                                        multiple={false}
                                        fileList={false}
                                        customRequest={() => null}
                                        onChange={this.onUploadChange.bind(this)}
                                    >
                                        <div>
                                            {!this.state.previewImage && <Icon type='plus'/>}
                                            <div className="ant-upload-text">{this.state.previewImage ? this.state.previewImage : 'Select File'}</div>
                                        </div>
                                        
                                    </Upload.Dragger>
                                )}
                            </div>
                        </FormItem>
                        <button type="submit" disabled={!this.state.ableUpload}>Submit</button>
                    </Form>
                </Spin>
            </Modal>
        )
            ;
    }
}

UploadPhotoModal.defaultProps = {
    ableSubmissionUsers: []
}

const WrappedUploadPhotoModal = Form.create()(UploadPhotoModal);

export default WrappedUploadPhotoModal;