/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The BagorderCreateBagOrderParam model module.
 * @module model/BagorderCreateBagOrderParam
 * @version 1.0
 */
class BagorderCreateBagOrderParam {
    /**
     * Constructs a new <code>BagorderCreateBagOrderParam</code>.
     * @alias module:model/BagorderCreateBagOrderParam
     */
    constructor() { 
        
        BagorderCreateBagOrderParam.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BagorderCreateBagOrderParam</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BagorderCreateBagOrderParam} obj Optional instance to populate.
     * @return {module:model/BagorderCreateBagOrderParam} The populated <code>BagorderCreateBagOrderParam</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BagorderCreateBagOrderParam();

            if (data.hasOwnProperty('bagType')) {
                obj['bagType'] = ApiClient.convertToType(data['bagType'], 'String');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'String');
            }
            if (data.hasOwnProperty('isDelivered')) {
                obj['isDelivered'] = ApiClient.convertToType(data['isDelivered'], 'Boolean');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} bagType
 */
BagorderCreateBagOrderParam.prototype['bagType'] = undefined;

/**
 * @member {String} date
 */
BagorderCreateBagOrderParam.prototype['date'] = undefined;

/**
 * @member {Boolean} isDelivered
 */
BagorderCreateBagOrderParam.prototype['isDelivered'] = undefined;

/**
 * @member {Number} quantity
 */
BagorderCreateBagOrderParam.prototype['quantity'] = undefined;

/**
 * @member {Number} userId
 */
BagorderCreateBagOrderParam.prototype['userId'] = undefined;






export default BagorderCreateBagOrderParam;

