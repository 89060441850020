/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrderOrderItemQueryResult model module.
 * @module model/OrderOrderItemQueryResult
 * @version 1.0
 */
class OrderOrderItemQueryResult {
    /**
     * Constructs a new <code>OrderOrderItemQueryResult</code>.
     * @alias module:model/OrderOrderItemQueryResult
     */
    constructor() { 
        
        OrderOrderItemQueryResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OrderOrderItemQueryResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderOrderItemQueryResult} obj Optional instance to populate.
     * @return {module:model/OrderOrderItemQueryResult} The populated <code>OrderOrderItemQueryResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderOrderItemQueryResult();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('revisedQuantity')) {
                obj['revisedQuantity'] = ApiClient.convertToType(data['revisedQuantity'], 'Number');
            }
            if (data.hasOwnProperty('unitPrice')) {
                obj['unitPrice'] = ApiClient.convertToType(data['unitPrice'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
OrderOrderItemQueryResult.prototype['id'] = undefined;

/**
 * @member {String} name
 */
OrderOrderItemQueryResult.prototype['name'] = undefined;

/**
 * @member {Number} quantity
 */
OrderOrderItemQueryResult.prototype['quantity'] = undefined;

/**
 * @member {Number} revisedQuantity
 */
OrderOrderItemQueryResult.prototype['revisedQuantity'] = undefined;

/**
 * @member {Number} unitPrice
 */
OrderOrderItemQueryResult.prototype['unitPrice'] = undefined;






export default OrderOrderItemQueryResult;

