import React, { Component } from 'react';
import { connect } from 'react-redux';

// import plasticBag1 from './plastic-bag-1.png';
// import plasticBag2 from './plastic-bag-2.png';
// import plasticBag3 from './plastic-bag-3.png';
// import plasticBag4 from './plastic-bag-4.png';
// import plasticBag5 from './plastic-bag-5.png';
// import slogan from './footer-bg-slogan.png';
import bg from './footer-bg-new.png';
import './Footer.scss';

import scripts from './translations.js';

export class Footer extends Component {

    render() {
        const { langCode, isImageHidden } = this.props;

        return (
            <div className="Footer">
                {
                    !isImageHidden && <div className="footer-section footer-section-1"></div>
                }
                <div className="footer-section footer-section-2">
                    <div className="footer-section-2-wrapper">
                        <p>
                            <a href="//www.reconnect.org.hk/">Reconnect Limited</a> © 2018 All Rights Reserved | Powered By <a href="//www.futureimpactlab.com">Future Impact Lab Limited</a> ｜ <a href="/about.html">About</a>
                        </p>
                    </div>
                </div>
                <div className="footer-section footer-section-3">
                    <p>{scripts[langCode]['disclaimer']}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps)(Footer);