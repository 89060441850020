import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import cx from 'classnames';

import * as actions from '../../services/student/actions';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './WebInterface.scss';

export class WebInterface extends Component {
    redirectToLandingPage() {
        const { history, location } = this.props;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId/:page',
            exact: true,
            strict: false
        });
        const { device, schoolId } = match.params;

        history.push(`/${device}/${schoolId}`);
    }

    redirectToLoginPage() {
        const { history, location } = this.props;
        let match = matchPath(location.pathname, {
            path: '/:device/:schoolId',
            strict: false
        });
        const { device, schoolId } = match.params;

        history.push(`/${device}/${schoolId}/helper-login`);
    }

    handleLogout(e) {
        const { history, location, updateStudentUserInfo } = this.props;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId',
            strict: false
        });
        const { device, schoolId } = match.params;

        updateStudentUserInfo({ class: '', isGaming: false, isHelper: false });
        history.push(`/${device}/${schoolId}`);
    }

    render() {
        const { location, children, selectedClass, isGaming } = this.props;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId/:page',
            exact: true,
            strict: false
        });
        const isLoginPage = match && (match.params.page === 'login' || match.params.page === 'helper-login');
        const isStudentHeplerPanel = match && match.params.page === 'student-helper-panel';

        return (
            <div className="WebInterface">
                <Header 
                    isMultiLingual={true}
                    isLoginBtnAvailable={!isGaming}
                    isBackBtnAvailable={isLoginPage}
                    selectedClass={selectedClass} 
                    onLoginBtnClick={(e) => this.redirectToLoginPage()}
                    onLogoutBtnClick={(e) => this.handleLogout(e)}
                    onBackBtnClick={(e) => this.redirectToLandingPage()}
                />
                <div className={cx('container', {'stretched' : isStudentHeplerPanel})}>
                    {children}
                </div>
                <Footer
                    isImageHidden={isStudentHeplerPanel}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedClass: state.student.class,
    isGaming: state.student.isGaming,
    isHelper: state.student.isHelper
})

export default connect(mapStateToProps, actions)(WebInterface);