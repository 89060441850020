import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { Tabs } from 'antd';
import Youtube from 'react-youtube';

import UploadPhotoModal from '../../../../components/UploadPhotoModal/UploadPhotoModal';
import FullWidthCartoonButton from '../../../../components/FullWidthCartoonButton/FullWidthCartoonButton';
import ButtonWithIcon from '../../../../components/ButtonWithIcon';
import RankingTable from '../../../../components/RankingTable/RankingTable';
import CartoonModal from '../../../../components/CartoonModal/CartoonModal';
import PopupVideoPlayer from '../../../../components/PopupVideoPlayer';

import Banner from './banner.jpg';
import UploadBanner from './upload-banner.jpg';
import BtnIcon1 from './btn-icon-1.png';
import BtnIcon2 from './btn-icon-2.png';
import BtnIcon3 from './btn-icon-3.png';
import './LandingPage.scss';
import scripts from './translations.js';
import {OrgUserApi} from "../../../../apiClient";


import OrgCompetitionApi from "../../../../apiClient/api/OrgCompetitionApi";

const { TabPane } = Tabs;
const PAGINATION_SIZE = 3;

export class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadPhotoModalVisible: false,
            ableSubmissionUsers: [],
            submissionPlaylist: [],
            submissionPage: 1,
            isSubmittingVideo: false,
            isModalVisible: false,
            modalMessage: '',
            isPopupVideoPlayerVisible: false,
            targetVideoUrl: undefined
        }
    }

    componentDidMount(){
        this.loadOrgUser();
        this.loadSubmission();
    }

    loadOrgUser(){
        new OrgUserApi().orgUsers(window.schoolId).then((data) => {
            let ableSubmissionUsers = [];
            data.users.forEach((v) => {
                if(v.role == "user"){
                    ableSubmissionUsers.push({
                        id: v.id,
                        // name = class
                        name: v.name
                    });
                }
            });
            this.setState({ableSubmissionUsers: ableSubmissionUsers})
        }).catch((e) => {

        })
    }

    loadSubmission(){
        new OrgCompetitionApi().orgCompetitionSubmissions(window.schoolId, 1).then(({submissions}) => {
            this.setState({submissionPlaylist: submissions})
        }).catch((e) => {

        })
    }

    createCompetitionSubmission(inputData){
        this.setState({isSubmittingVideo: true});
        // Default competition id is 1
        new OrgCompetitionApi().orgCompetitionUploadCompetitionSubmission(window.schoolId, 1, inputData.photo.file.originFileObj).then((uploadFileResult)=>{
            return new OrgCompetitionApi().orgCompetitionCreateSubmission(window.schoolId, 1, {
                applicant: inputData.name,
                userId: inputData.class,
                filePath: uploadFileResult.filePath,
            })
        }).then((data)=>{
            this.setState({isSubmittingVideo: false, uploadPhotoModalVisible: false, isModalVisible: true, modalMessage: scripts[this.props.langCode].modalCompetitionSubmissionSuccessMessage});
        }).catch((e) =>{
            this.setState({isSubmittingVideo: false,isModalVisible: true, modalMessage: scripts[this.props.langCode].modalCompetitionSubmissionErrMessage});
        })

    }

    render() {
        const { langCode, location } = this.props;
        const { uploadPhotoModalVisible, isSubmittingVideo, isModalVisible, modalMessage, isPopupVideoPlayerVisible, targetVideoUrl, submissionPlaylist, submissionPage } = this.state;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId',
            exact: true,
            strict: false
        });
        const { device, schoolId } = match.params;
        const isKiosk = device === 'kiosk';


        return (
            <div className="WebLandingPage">
                <div className="banner-section">
                    <Youtube
                        opts={{
                            height: isKiosk ? 506 : 692,
                            width: '100%',
                            playerVars: {
                                playlist: 'Ph_m5A-bXag',
                                loop: 1,
                                rel: 0,
                                autoplay: 1,
                            }
                        }}
                    />
                </div>
                <div className="btn-row">
                    <ButtonWithIcon 
                        iconUrl={BtnIcon1}
                        btnText={scripts[langCode].enterGame} 
                        targetUrl={`/${device}/${schoolId}/login`}
                    />
                    {/* <ButtonWithIcon 
                        iconUrl={BtnIcon2}
                        btnText={scripts[langCode].login} 
                        targetUrl={`/${device}/${schoolId}/helper-login`}
                    /> */}
                    {/* <ButtonWithIcon 
                        iconUrl={BtnIcon3}
                        btnText={scripts[langCode].submission} 
                        onClick={()=> this.setState({ uploadPhotoModalVisible: true })} 
                    /> */}
                </div>
                {/* <Tabs>
                    <TabPane tab={scripts[langCode].tabVideos} key="videos">
                        <div className="uploaded-video-section">
                            {
                                submissionPlaylist.map((v, i)=> {
                                    if (i < submissionPage * PAGINATION_SIZE) {
                                        return (
                                            <div 
                                                key={i}
                                                className="uploaded-video"
                                                onClick={() => this.setState({ isPopupVideoPlayerVisible: true, targetVideoUrl: process.env.REACT_APP_VIDEO_URL + v.filePath})}
                                            >
                                                <div className="description-overlay">{v.user.name} {v.applicant}</div>
                                                <img src={process.env.REACT_APP_VIDEO_URL + v.filePath + '.jpg'} alt=""/>
                                            </div>
                                        );
                                    }
                                })
                            }
                            {
                                submissionPlaylist.length - submissionPage * PAGINATION_SIZE > 0 &&
                                <div 
                                    className="load-more-btn" 
                                    onClick={(e) => this.setState({ submissionPage : submissionPage + 1 })}
                                >
                                    {scripts[langCode].loadMore}
                                </div>
                            }
                        </div>
                    </TabPane>
                    <TabPane tab={scripts[langCode].tabRanking} key="ranking">
                        <RankingTable langCode={langCode} height={600}/>
                    </TabPane>
                </Tabs> */}
                <UploadPhotoModal
                    ableSubmissionUsers={this.state.ableSubmissionUsers}
                    visible={uploadPhotoModalVisible}
                    afterSubmit={this.createCompetitionSubmission.bind(this)}
                    onCancel={(e) => this.setState({ uploadPhotoModalVisible: false })}
                    title={scripts[langCode].gameInstructionTitle}
                    description={scripts[langCode].gameInstructionDescription}
                    howToUpload={scripts[langCode].howToUpload}
                    isSubmitting={isSubmittingVideo}
                />
                <CartoonModal
                    visible={isModalVisible}
                    onOk={(e) => this.setState({isModalVisible: false})}
                    onCancel={(e) => this.setState({isModalVisible: false})}
                    message={modalMessage}
                    btnText={scripts[langCode].ok}
                />
                <PopupVideoPlayer
                    visible={isPopupVideoPlayerVisible}
                    videoUrl={targetVideoUrl}
                    onCancel={() => this.setState({ isPopupVideoPlayerVisible: false })}
                    afterClose={() => this.setState({ targetVideoUrl: undefined })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps)(LandingPage);