import React, {Component} from 'react';
import {connect} from 'react-redux';
import {matchPath} from 'react-router-dom';
import _ from 'lodash';

import * as actions from '../../../../services/student/actions';
import SelectorBox from '../../../../components/SelectorBox/SelectorBox';
import CartoonModal from '../../../../components/CartoonModal/CartoonModal';
import loginIcon from './login-icon.png';
import './LoginPage.scss';

import {OrgUserApi, AuthApi, AuthLoginParam, ApiClient} from '../../../../apiClient';

import scripts from './translations.js';

const API_URL = process.env.API_URL;

export class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPrimary: undefined,
            selectedClass: undefined,
            password: '',
            isModalVisible: false,
            modalMessage: '',
            availableUser: {
                user: {},
                primaries: [],
                classes: [],
            },
        };
    }

    componentDidMount() {
        new OrgUserApi().orgUsers(window.schoolId).then((data) => {
            var availableUser = {
                user: {},
                primaries: [],
                classes: [],
            };

            const match = matchPath(this.props.location.pathname, {
                path: '/:device/:schoolId/:page',
                exact: true,
                strict: false
            });

            const {device, schoolId, page} = match.params;
            const role = page === 'helper-login' ? 'shopper': 'user';

            data.users.forEach((v) => {
                if(v.role == role){
                    availableUser.user[v.name] = v;
                    availableUser.primaries.push(v.name.charAt(0));
                    availableUser.classes.push(v.name.substring(1, v.name.length));
                }
            });

            availableUser.primaries = _.uniq(availableUser.primaries);
            availableUser.classes = _.uniq(availableUser.classes);

            this.setState({availableUser: availableUser})
        }).catch((e) => {

        })
    }

    handleLogin(e) {
        const {langCode, updateStudentUserInfo, history, location} = this.props;
        const {selectedPrimary, selectedClass, password} = this.state;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId/:page',
            exact: true,
            strict: false
        });
        const {device, schoolId, page} = match.params;
        const isHelperLogin = page === 'helper-login';

        if (selectedPrimary === undefined || selectedClass === undefined) {
            this.setState({isModalVisible: true, modalMessage: scripts[langCode].modalClassErrMessage});
        } else if (isHelperLogin && password === '') {
            this.setState({isModalVisible: true, modalMessage: scripts[langCode].modalPasswordErrMessage});
        } else {
            let defaultPassword = 'user';
            new AuthApi().authLogin({authLoginParam: new AuthLoginParam(isHelperLogin ? password : defaultPassword, this.state.availableUser.user[this.state.selectedPrimary + this.state.selectedClass].username)}).then((data)=>{
                ApiClient.instance.authentications['ApiKeyAuth'].apiKey = "Bearer " + data.token;
                updateStudentUserInfo({
                    class: `${selectedPrimary}${selectedClass}`,
                    isGaming: !isHelperLogin,
                    isHelper: isHelperLogin
                });
                if (isHelperLogin)
                    history.push(`/${device}/${schoolId}/student-helper-panel`);
                else
                    history.push(`/${device}/${schoolId}/game`);
            }).catch(()=>{
                this.setState({isModalVisible: true, modalMessage: scripts[langCode].modalPasswordIncorrectErrMessage});
            });
        }
    }

    render() {
        const {langCode, location} = this.props;
        const {selectedPrimary, selectedClass, password, isModalVisible, modalMessage} = this.state;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId/:page',
            exact: true,
            strict: false
        });
        const {page} = match.params;
        const isHelperLogin = page === 'helper-login';

        return (
            <div className="LoginPage">
                <h2>{isHelperLogin ? scripts[langCode].helperInstruction : scripts[langCode].gameInstruction}</h2>
                <div className="selection-row">
                    <h3>{scripts[langCode].primary}</h3>
                    <div className="selection-options-row">
                        {
                            _.map(this.state.availableUser.primaries, (i) => (
                                <SelectorBox
                                    key={`primary-option-${i}`}
                                    style={{background: '#d6de23'}}
                                    text={i}
                                    isSelected={selectedPrimary === i}
                                    onClick={(e) => this.setState({selectedPrimary: i})}
                                />
                            ))
                        }
                    </div>
                </div>
                <div className="selection-row">
                    <h3>{scripts[langCode].classLabel}</h3>
                    <div className="selection-options-row">
                        {
                            _.map(this.state.availableUser.classes, (char, i) => (
                                <SelectorBox
                                    key={`class-option-${i}`}
                                    style={{background: '#00aec1'}}
                                    text={char}
                                    isSelected={selectedClass === char}
                                    onClick={(e) => this.setState({selectedClass: char})}
                                />
                            ))
                        }
                    </div>
                </div>
                {
                    !isHelperLogin &&
                    <div className="start-game-row">
                            <button
                            className="bw-btn"
                            type="button"
                            disabled={this.state.availableUser.user[this.state.selectedPrimary + this.state.selectedClass] ? '' : 'disabled'}
                            onClick={(e) => this.handleLogin(e)}
                            >
                            {scripts[langCode].startGame}
                            </button>
                            </div>
                        }
                        {
                            isHelperLogin &&
                            <div className="password-row">
                                <h3>{scripts[langCode].enterPassword}</h3>
                                <div className="password-section">
                                    <input
                                        type="password"
                                        placeholder={scripts[langCode].password}
                                        autoComplete="autocompleteisnotworkhere"
                                        value={password}
                                        onChange={(e) => this.setState({password: e.target.value})}
                                    />
                                    <button
                                        className="bw-btn"
                                        type="button"
                                        disabled={this.state.availableUser.user[this.state.selectedPrimary + this.state.selectedClass] ? '' : 'disabled'}
                                        onClick={(e) => this.handleLogin(e)}
                                    >
                                        {scripts[langCode].login}
                                    </button>
                                </div>
                            </div>
                        }
                        <CartoonModal
                            visible={isModalVisible}
                            onOk={(e) => this.setState({isModalVisible: false})}
                            onCancel={(e) => this.setState({isModalVisible: false})}
                            message={modalMessage}
                            btnText={scripts[langCode].ok}
                        />
                    </div>
                    );
                }
                }

                const mapStateToProps = (state) => ({
                langCode: state.translation.langCode
            });

                export default connect(mapStateToProps, actions)(LoginPage);