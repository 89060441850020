import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import cx from 'classnames';
import _ from 'lodash';

import * as actions from '../../services/translation/actions';
import { translationOptions } from '../../utils/options';
import leftLogo from './left_logo.png';
import MainLogo from './main_logo.png';
import rightLogo from './right_logo.png';
import './Header.scss';

import scripts from './translations.js';

export class Header extends Component {
    render() {
        const { updateTranslationSetting, isMultiLingual, langCode, isBackBtnAvailable, isLoginBtnAvailable, selectedClass, onBackBtnClick, onLoginBtnClick, onLogoutBtnClick } = this.props;
        const languageBtns = _.map(translationOptions, (option) =>
            <div 
                key={option.value}
                className={cx({
                    "language-btn": true,
                    "selected": langCode === option.value
                })}
                onClick={(e) => updateTranslationSetting(option.value)}
            >
                {option.label}
            </div>
        );

        return (
            <div className="Header">
				<div className="navbar">
					<div className="navbar-wrapper">
						<div className="logo-gp">
							<img className="sub" src={leftLogo} alt="Reconnect"/>
							<img className="main" src={MainLogo} alt="Big Waster"/>
							<img className="sub" src={rightLogo} alt="Reconnect"/>
						</div>
						<div className="btn-section">
							<div className="left-section">
								{
									isBackBtnAvailable &&
									<div className="bw-btn back-btn" onClick={(e) => onBackBtnClick(e)}>
										<Icon type="rollback" />Back
									</div>
								}
							</div>
							<div className="right-section">
								<div className="class-label">
									{ selectedClass && `${scripts[langCode].class}: ${selectedClass}` }
								</div>
								{
									isLoginBtnAvailable &&
									<div className="login-btn" style={{ visibility: selectedClass ? 'visible' : 'hidden' }} onClick={(e) => selectedClass ? onLogoutBtnClick(e): onLoginBtnClick(e)}>
										{ selectedClass ? scripts[langCode].logout : scripts[langCode].login }
									</div>
								}
							</div>
						</div>
						{
							isMultiLingual &&
		                    <div className="language-btn-gp">
		                        {languageBtns}
		                    </div>
						}
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps, actions)(Header);