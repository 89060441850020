/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrgUpdateOrgParam model module.
 * @module model/OrgUpdateOrgParam
 * @version 1.0
 */
class OrgUpdateOrgParam {
    /**
     * Constructs a new <code>OrgUpdateOrgParam</code>.
     * @alias module:model/OrgUpdateOrgParam
     */
    constructor() { 
        
        OrgUpdateOrgParam.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OrgUpdateOrgParam</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrgUpdateOrgParam} obj Optional instance to populate.
     * @return {module:model/OrgUpdateOrgParam} The populated <code>OrgUpdateOrgParam</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrgUpdateOrgParam();

            if (data.hasOwnProperty('abbv')) {
                obj['abbv'] = ApiClient.convertToType(data['abbv'], 'String');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'String');
            }
            if (data.hasOwnProperty('defaultLangCode')) {
                obj['defaultLangCode'] = ApiClient.convertToType(data['defaultLangCode'], 'String');
            }
            if (data.hasOwnProperty('maxMcPlayPerDay')) {
                obj['maxMcPlayPerDay'] = ApiClient.convertToType(data['maxMcPlayPerDay'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} abbv
 */
OrgUpdateOrgParam.prototype['abbv'] = undefined;

/**
 * @member {String} address
 */
OrgUpdateOrgParam.prototype['address'] = undefined;

/**
 * @member {String} defaultLangCode
 */
OrgUpdateOrgParam.prototype['defaultLangCode'] = undefined;

/**
 * @member {Number} maxMcPlayPerDay
 */
OrgUpdateOrgParam.prototype['maxMcPlayPerDay'] = undefined;

/**
 * @member {String} name
 */
OrgUpdateOrgParam.prototype['name'] = undefined;






export default OrgUpdateOrgParam;

