/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ItemItemTranslationParam from './ItemItemTranslationParam';

/**
 * The ItemQueryResult model module.
 * @module model/ItemQueryResult
 * @version 1.0
 */
class ItemQueryResult {
    /**
     * Constructs a new <code>ItemQueryResult</code>.
     * @alias module:model/ItemQueryResult
     */
    constructor() { 
        
        ItemQueryResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ItemQueryResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ItemQueryResult} obj Optional instance to populate.
     * @return {module:model/ItemQueryResult} The populated <code>ItemQueryResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ItemQueryResult();

            if (data.hasOwnProperty('availableOrgIds')) {
                obj['availableOrgIds'] = ApiClient.convertToType(data['availableOrgIds'], ['Number']);
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('translation')) {
                obj['translation'] = ApiClient.convertToType(data['translation'], {'String': ItemItemTranslationParam});
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<Number>} availableOrgIds
 */
ItemQueryResult.prototype['availableOrgIds'] = undefined;

/**
 * @member {String} createdAt
 */
ItemQueryResult.prototype['createdAt'] = undefined;

/**
 * @member {String} deletedAt
 */
ItemQueryResult.prototype['deletedAt'] = undefined;

/**
 * @member {Number} id
 */
ItemQueryResult.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ItemQueryResult.prototype['name'] = undefined;

/**
 * @member {Number} price
 */
ItemQueryResult.prototype['price'] = undefined;

/**
 * @member {Object.<String, module:model/ItemItemTranslationParam>} translation
 */
ItemQueryResult.prototype['translation'] = undefined;

/**
 * @member {String} updatedAt
 */
ItemQueryResult.prototype['updatedAt'] = undefined;






export default ItemQueryResult;

