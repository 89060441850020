import React, { setState } from 'react';
import ReactPlayer from 'react-player'
import { Modal } from 'antd';

import './style.scss';

const PopupVideoPlayer = (props) => {
    const { visible ,videoUrl, onCancel, afterClose } = props;

    return (
        <Modal
            className="PopupVideoPlayer"
            width="60%"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            afterClose={afterClose}
        >
            <ReactPlayer
                width="100%"
                height="auto"
                // url={process.env.REACT_APP_API_URL + '/' + videoUrl}
                url={videoUrl}
                playing={visible}
                controls
            />
        </Modal>
    )
}

export default PopupVideoPlayer;