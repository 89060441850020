/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BagusageQueryResult from './BagusageQueryResult';

/**
 * The BagusageOutput model module.
 * @module model/BagusageOutput
 * @version 1.0
 */
class BagusageOutput {
    /**
     * Constructs a new <code>BagusageOutput</code>.
     * @alias module:model/BagusageOutput
     */
    constructor() { 
        
        BagusageOutput.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BagusageOutput</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BagusageOutput} obj Optional instance to populate.
     * @return {module:model/BagusageOutput} The populated <code>BagusageOutput</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BagusageOutput();

            if (data.hasOwnProperty('bagUsage')) {
                obj['bagUsage'] = ApiClient.convertToType(data['bagUsage'], [BagusageQueryResult]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/BagusageQueryResult>} bagUsage
 */
BagusageOutput.prototype['bagUsage'] = undefined;






export default BagusageOutput;

